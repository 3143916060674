export const ADD_TO_CART = "ADD_TO_CART";
export const DECREASE_QUANTITY = "DECREASE_QUANTITY";
export const DELETE_FROM_CART = "DELETE_FROM_CART";
export const DELETE_ALL_FROM_CART = "DELETE_ALL_FROM_CART";

//add to cart
export const addToCart = (
  item,
  addToast,
  quantityCount,
  priceItem = 0
) => {
  return dispatch => {
    if (addToast) {
      addToast("Agregado al carrito", { appearance: "success", autoDismiss: true });
    }
    dispatch({
      type: ADD_TO_CART,
      payload: {
        ...item,
        quantity: quantityCount,
        price: priceItem
      }
    });
  };
};
//decrease from cart
export const decreaseQuantity = (item, addToast, quantityCount = 1) => {
  return dispatch => {
    if (addToast) {
      addToast("Item disminuido del carrito", {
        appearance: "warning",
        autoDismiss: true
      });
    }
    dispatch({
      type: DECREASE_QUANTITY, payload: {
        ...
        item,
        quantityDecrease: quantityCount
      }
    });
  };
};
//delete from cart
export const deleteFromCart = (item, addToast) => {
  return dispatch => {
    if (addToast) {
      addToast("Eliminado del carrito", { appearance: "error", autoDismiss: true });
    }
    dispatch({ type: DELETE_FROM_CART, payload: item });
  };
};
//delete all from cart
export const deleteAllFromCart = addToast => {
  return dispatch => {
    if (addToast) {
      addToast("Todos los productos eliminados del carrito", {
        appearance: "error",
        autoDismiss: true
      });
    }
    dispatch({ type: DELETE_ALL_FROM_CART });
  };
};

// get stock of cart item
export const cartItemStock = (item, color) => {
  if (item.Disponible) {
    return item.Disponible;
  } 
};
